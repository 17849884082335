<template>
  <div class="container-page">
    <p class="title-inner">Экспортный потенциал товаров</p>
    <div class="mb-16">
      <ButtonStock
        :disabled="importInProgress"
        :title="!importInProgress ? 'Загрузить из Excel' : 'Загрузка...'"
        @click="importExcel()"
      />
      <ButtonStock class="ml-2" title="Сохранить в Excel" @click="exportExcel" :disabled="exportInProgress" />
    </div>
    <div class="mb-4">
      <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
    </div>
    <div>
      <div class="table-container mb-16 js-table-arrow">
        <table class="stockTable">
          <thead>
            <tr>
              <th>Коды ТН ВЭД</th>
              <th>Потенциал, млн $ США</th>
              <th>Фактический потенциал, млн $ США</th>
              <th>Фактический экспорт, млн $ США</th>
              <th>Страна-экспортер</th>
              <th>Страна-импортер</th>
              <th></th>
            </tr>
            <tr>
              <th class="filter">
                <FilterInput
                  placeholder="Код ТН ВЭД"
                  v-model="filter.codeNsiTnved"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  placeholder="От"
                  v-model="filter.valueFrom"
                  type="number"
                  v-on:valueChangedDebounced="changeFilter"
                />
                <FilterInput
                  placeholder="До"
                  v-model="filter.valueTo"
                  type="number"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  placeholder="От"
                  v-model="filter.exportValueFrom"
                  type="number"
                  v-on:valueChangedDebounced="changeFilter"
                />
                <FilterInput
                  placeholder="До"
                  v-model="filter.exportValueTo"
                  type="number"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  placeholder="От"
                  v-model="filter.exportFactFrom"
                  type="number"
                  v-on:valueChangedDebounced="changeFilter"
                />
                <FilterInput
                  placeholder="До"
                  v-model="filter.exportFactTo"
                  type="number"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  :select2Options="countryList"
                  placeholder="Выберите"
                  type="select2"
                  v-model="filter.fromCountryId"
                  :select2Settings="select2Settings"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  :select2Options="countryList"
                  placeholder="Выберите"
                  type="select2"
                  v-model="filter.toCountryId"
                  :select2Settings="select2Settings"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter"></th>
            </tr>
          </thead>
          <tbody v-if="page.items?.length && !isLoading">
            <tr v-for="item in page.items" :key="item.id">
              <td>{{ item.itemsNsiTnved?.length ? item.itemsNsiTnved.map((x) => x.code).join('\n') : '' }}</td>
              <td>{{ item.value }}</td>
              <td>{{ item.exportValue }}</td>
              <td>{{ item.exportFact }}</td>
              <td>{{ item.fromCountry?.name }}</td>
              <td>{{ item.toCountry?.name }}</td>
              <td>
                <IconComponent @click="editCard(item.id)" name="mode" />
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="isLoading">
            <tr>
              <td class="text-center" colspan="5">Загрузка...</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="5">Нет данных.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="page.pagesTotal > 1">
        <Pagination
          :page-count="page.pagesTotal"
          :current-page="page.pageNumber"
          @change="onPageChange($event, false)"
          @more="onPageChange($event, true)"
        />
      </div>
    </div>
    <section>
      <ModalComponent v-model="importModal" title="Импорт файла" @close="closeModal">
        <ImportModal @doimport="doImport" @close="closeModal" />
      </ModalComponent>
    </section>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import FilterInput from '@/common/components/FilterInput';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import Utils from '@/common/utils';

  import ImportModal from '@/components/modals/ImportModal';

  import IconComponent from '../../../common/ui/IconComponent';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import ModalComponent from '../../../components/modals/ModalComponent';
  import Api from '../api/index';
  export default {
    name: 'ExportPotential',
    components: { ImportModal, ModalComponent, ButtonStock, FilterInput, DefaultFilter, IconComponent },
    mixins: [filtermanager],
    data() {
      return {
        route: 'exportpotential',
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        importInProgress: false,
        exportInProgress: false,
        importModal: false,
        isLoading: false,

        select2Settings: Constants.select2Settings,
        countryList: [],
        filter: {
          codeNsiTnved: '',
          toCountryId: null,
          fromCountryId: null,
          valueFrom: null,
          valueTo: null,
          exportValueFrom: null,
          exportValueTo: null,
        },
      };
    },
    created() {
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        this.loadDictionaries();
        this.loadPage();
      });
    },
    methods: {
      loadDictionaries() {
        Utils.loadSelectOptions('countryList', this.countryList, true);
      },
      loadPage(append) {
        this.isLoading = !append;
        var params = Object.assign(this.filter, this.request);
        Api.search(this.route, params)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      importExcel() {
        this.importModal = true;
      },
      doImport(file) {
        this.closeModal();
        this.importInProgress = true;
        Api.downloadFile(this.route, file)
          .then(() => {
            Constants.alert.fire('Импорт', 'Процесс импорта запущен. Ожидайте уведомление о результате.', 'success');
            this.loadPage();
            this.importInProgress = false;
          })
          .catch((error) => {
            console.log(error?.response?.data);
            Constants.alert.fire('Импорт', 'Ошибка при импорте данных.', 'error');
            this.importInProgress = false;
          });
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      closeModal() {
        this.importModal = false;
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      exportExcel() {
        this.exportInProgress = true;
        Api.exportExcel(this.filter).then(() => {
          this.exportInProgress = false;
        });
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
      editCard(id) {
        this.$router.push({ name: 'ExportPotentialCard', params: { id: id, action: 'edit' } });
      },
    },
  };
</script>

<style scoped></style>
